@import "../vars";
@import "../mixins";

.gallery {
    $transition-time: all 500ms;

    &--small {
        background: $primary-color;
        margin-right: -3px;
        padding-top: 3px;
        margin-bottom: -3px;
    }

    @include grid();

    &:last-of-type {
        margin-bottom: $base-spacing * 1.5;
    }

    &__spacer {
        flex: 1;
        background: #000;
    }

    &__picture {
        width: 25%;
        margin-bottom: 0;
        position: relative;
        overflow: hidden;

        //@include styleBelow(1024px) {
        //  @include gridItem(100%, 3, 0px);
        //}

        @include styleBelow(900px) {
            width: 50%;
        }

        @include styleBelow(425px) {
            width: 25%;
        }

        &--small {
            margin-right: 3px;
            margin-bottom: 3px;
            width: calc(12.5% - 3px);

            @include styleBelow(1024px) {
                width: calc(25% - 3px);
            }

            @include styleBelow(425px) {
                width: calc(50% - 3px);
            }
        }

        &:hover {
            & img {
                filter: blur(3px);
                transform: scale(1.2);
            }

            & .gallery__buttons {
                top: 46%;
                opacity: 1;
                visibility: visible;
            }
        }

        &--more {
            img {
                filter: blur(3px);
                transform: scale(1.2);

            }
        }

    }

    img {
        display: block;
        width: 100%;
        height: auto;
        transition: $transition-time;
    }

    &__buttons {
        position: absolute;
        text-align: center;
        width: 100%;
        top: -40%;
        opacity: 0;
        visibility: hidden;
        transition: $transition-time, opacity 200ms;
        z-index: 1;

        &--active {
            top: 46%;
            opacity: 1;
            visibility: visible;
        }

    }

    &__button {
        //display: block;
        background-color: $primary-color;
        padding: 10px 16px;
        margin-right: $base-spacing/4;
        font-size: 1.3em;
        font-weight: lighter;
        border: 3px solid $primary-color;
        color: $font-color;
        opacity: 0.8;

        transition: $transition-time;

        @include styleBelow(1500px) {
            padding: 7px 10px;
        }

        @include styleBelow(1024px) {
            padding: 6px 8px;
        }

        @include styleBelow(768px) {
            padding: 6px 8px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: $primary-color;
        }

        .svg-inline--fa {
            justify-content: center;
            vertical-align: middle;
        }
    }
}

.detail-content {
    $detail-container-size: 1000px;
    $transition-time: all 500ms;

    &__gallery {
        @include container($detail-container-size);
        @include grid();
        margin-bottom: $base-spacing * 1.5;
    }

    &__picture {
        @include gridItem(100%, 3, 0px);
        margin-bottom: 0;
        position: relative;
        overflow: hidden;

        @include styleBelow(950px) {
            @include gridItem(100%, 2, 0px);
        }

        @include styleBelow(425px) {
            @include gridItem(100%, 1, 0px);
        }

        &:hover {
            & img {
                filter: blur(3px);
                transform: scale(1.2);
            }

            & .detail-content__buttons {
                top: 46%;
                opacity: 1;
                visibility: visible;
            }
        }
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        transition: $transition-time;
    }

    &__buttons {
        position: absolute;
        text-align: center;
        width: 100%;
        top: -40%;
        opacity: 0;
        visibility: hidden;
        transition: $transition-time, opacity 200ms;
        z-index: 1;
    }

    &__button {
        //display: block;
        background-color: $primary-color;
        padding: 12px;
        margin-right: $base-spacing/4;
        font-size: 1.2em;
        font-weight: lighter;
        border: 3px solid $primary-color;
        color: $font-color;
        opacity: 0.8;
        transition: $transition-time;

        @include styleBelow(800px) {
            font-size: 1em;
            padding: 6px 9px;
        }

        @include styleBelow(425px) {
            font-size: 1.2em;
            padding: 8px 11px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: $primary-color;
        }
    }
}

