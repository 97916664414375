@import "../vars";
@import "../mixins";

.livestream {
    @include container($container-size);
    //margin-bottom: $base-spacing*1.5;
    display: flex;

    a {
        @include styleBelow(880px) {
            flex: 1;
        }
    }

    @include styleBelow(1010px) {
        flex-flow: column;
    }

    @include styleBelow(1550px) {
        padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
        padding: 0 $base-spacing/2;
    }

    &__infos {
        display: flex;
        flex-flow: column;
        margin-left: $base-spacing*4;

        @include styleBelow(1360px) {
            margin-left: $base-spacing;
        }

        @include styleBelow(1010px) {
            margin: $base-spacing 0;
        }
    }

    &__headline {
        text-transform: uppercase;
        font-size: 1.3em;
        margin-bottom: $base-spacing/3;
        color: $primary-color;
        transition: $hover-transition-time;

        @include styleBelow(1360px) {
            font-size: 1.2em;
            margin-bottom: $base-spacing/4;
        }

        @include styleBelow(475px) {
            font-size: 1.1em;
        }

        &:hover {
            color: $light-grey;
        }
    }

    &__details {
        font-size: 1.1em;
        font-weight: lighter;

        @include styleBelow(475px) {
            font-size: 1em;
        }

        @include styleBelow(355px) {
            font-size: 0.9em;
        }
    }

    &__mobile-wrapper {
        display: flex;
        width: 100%;

        @include styleBelow(880px) {
            flex-flow: column;
            align-items: flex-start;
            width: auto;
        }

        @include styleBelow(750px) {
            align-items: flex-start;
            margin-top: $base-spacing;
        }

        @include styleBelow(425px) {
            margin-top: $base-spacing/2;
        }
    }

    &__buttons {
        display: flex;
        flex-flow: row;
        margin-top: $base-spacing;

        @include styleBelow(1210px) {
            flex-flow: column;
        }

    }

    &__logo {
        @include styleBelow(750px) {

        }
    }

    &__button {
        color: $font-color;
        margin-right: $base-spacing/2;
        background-color: $primary-color;
        border: 3px solid $primary-color;
        padding: 7px 13px;
        text-transform: uppercase;
        font-weight: normal;
        letter-spacing: 1px;
        transition: $hover-transition-time;

        @include styleBelow(1250px) {
            padding: 3px 9px;
            font-size: 0.9em;
            text-align: center;
        }

        @include styleBelow(1210px) {
            margin-right: 0;
            margin-bottom: $base-spacing/3;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0);
        }

        &--red {
            background-color: $button-color;
            border: 3px solid $button-color;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__spacer {
        flex: 1;
    }


    //fix for livestream paddings
    #player {
        margin: 0 -1em -1em;

        iframe {
            height: 126.5px !important;
        }

        @include styleBelow(1170px) {

        }
    }


}