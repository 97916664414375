@import "../vars";
@import "../mixins";

.detail-content {
  @include container($container-size);
  display: flex;
    margin-bottom: $base-spacing*1.5;

  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  @include styleBelow(768px) {
   flex-flow: column;
  }

  &__navigation-area {
    flex: 1;


    @include styleBelow(768px) {
      display: flex;
      flex-flow: column-reverse;
    }
 }

  &__navigation {
    padding-right: 100px;

    @include styleBelow(1410px){
      padding-right: 50px;
    }

    @include styleBelow(768px) {
      padding-right: 0;
      margin-bottom: $base-spacing*1.5;
    }
  }

  &__navigation-item {

    text-transform: uppercase;
    margin-bottom: $base-spacing/2;
    border-bottom: 1px solid $font-color;
    padding-bottom: $base-spacing/2;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &:last-of-type {
      border: none;
    }
  }

  &__mainnav {
    display: flex;
  }

  &__subnav {
    margin-left: 10px;
  }

   a {
     color: $font-color;
     transition: $hover-transition-time;
     margin-right: 8px;
     font-size: 1.2em;

     &:hover {
       color: $primary-color;
     }
   }

    a.active {
        color: $primary-color;
    }


    &__subnav {
        margin-left: $base-spacing/2;
        margin-top: 5px;
        font-size: 0.8em;

        .svg-inline--fa{
            font-size: 1em;
            margin-right: 5px;
            justify-items: baseline;

            &:hover {
                color: $primary-color;
            }
        }
    }

  &__more {
    flex: 1;
    text-align: right;
    font-size: 1.4em;
  }

  &__spacer{
    flex: 1;
  }

  &__info {
    flex: 3;
    max-width: 1000px;
  }


  &__ad-area {
    margin-top: $base-spacing*2;
    padding-right: 50px;

    @include styleBelow(768px) {
      padding-right: 0;
      margin-top: 0;
      margin-bottom: $base-spacing*1.5;
    }
  }

  &__ad {
    margin-bottom: $base-spacing/2;

  &:last-of-type {
    margin-bottom: 0;
  }

    img {
      display: block;
      width: 100%;
    }
  }
}