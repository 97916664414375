//Colors En Garde
//$primary-color: rgb(26, 175, 143);
//$light-turquoise: rgb(27, 232, 197);

$primary-color: #00b2a9;
$light-turquoise: rgba(0, 214, 205, 0.98);
$font-color: #ffffff;
$light-grey: #979797;
$lighter-grey: #dcdcdc;
$button-color: #e20000;
$footer-color: #161616;


//background-colors
$default-background: #000000;
$dark-grey-background: #323232;
$middle-grey-background: #525252;
$light-grey-background: #747474;


//animations
$hover-transition-time: 400ms !default;
$transition: all 800ms;


$base-spacing: 30px;
$container-size: 1500px;
$nav-height: 90px;
$presented-banner-height: 5px;