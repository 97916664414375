.medien-titel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00b2a9;
    color: white;
    padding: 0.5rem 0.7rem;
    transition: 0.4s;
    .medien-text {
        font-family: Aller,sans-serif;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: lighter;
        line-height: normal;
    }
    svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: currentColor;
    }
}
.medien-titel:hover {
    background-color: white;
    color: #00b2a9;
    transition: 0.4s;
}
.medien-linkless {
    display: flex;
    align-items: center;
    background-color: #00b2a9;
    color: white;
    padding: 0.575rem 0.7rem;
    cursor: default;
    .medien-text {
        font-family: Aller,sans-serif;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: lighter;
        line-height: normal;
    }
}
.no-link-cursor {
    cursor: default;
}