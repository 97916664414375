@import "../vars";
@import "../mixins";


.auflockerung {
  //position: relative;
  margin-bottom: $base-spacing*1.5;

  &__img {
    width: 100%;
      display: block;
  }

  &__quote-wrapper {
    @include container($container-size);

    @include styleBelow(1550px){
      padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
      padding: 0 $base-spacing/2;
    }
  }

  &__quote-text {
    position: absolute;
    top: $base-spacing*3;
    opacity: 0.6;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);

    @include styleBelow(1200px) {
      top: $base-spacing;
    }

    @include styleBelow(600px) {
      top: $base-spacing/2;
    }

    h2 {
      text-transform: uppercase;
      font-weight: normal;
      font-size: 2.2em;
      margin-bottom: $base-spacing/2;

      @include styleBelow(1024px) {
        font-size: 1.4em;
      }

      @include styleBelow(800px) {
        font-size: 1.2em;
        margin-bottom: 2px;
      }

      @include styleBelow(480px) {
        display: none;
      }
    }
  }

  &__quote-subline {
    font-size: 1.7em;
    font-weight: lighter;

    @include styleBelow(1024px) {
      font-size: 1.3em;
    }

    @include styleBelow(800px) {
      font-size: 1.2em;
    }

    @include styleBelow(800px) {
      font-size: 1.1em;
    }

    @include styleBelow(480px) {
      display: none;
    }
  }
}