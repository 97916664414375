@import "../vars";
@import "../mixins";

.archive {
    @include container();
    @include styleBelow($container-size) {
        margin-left: $base-spacing / 2;
        margin-right: $base-spacing / 2;
    }

    &__selector {
        background-color: $light-grey-background;
        border: none;
        padding: 5px 10px;
        font-size: 1.1rem;
        color: $font-color;
        cursor: pointer;
    }
}