@import "vars";
@import "mixins";
@import "fonts";
@import "../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";

@font-face {
    font-family: 'Martel Sans';
    font-style: normal;
    font-weight: 400;
    src: url('https://cdn-fonts.backslash-n.io/martel-sans/martel-sans-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('https://cdn-fonts.backslash-n.io/martel-sans/martel-sans-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://cdn-fonts.backslash-n.io/martel-sans/martel-sans-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/martel-sans/martel-sans-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/martel-sans/martel-sans-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://cdn-fonts.backslash-n.io/martel-sans/martel-sans-v12-latin-regular.svg#MartelSans') format('svg'); /* Legacy iOS */
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    //font-family: "Aller", sans-serif;
    //font-family: "work sans", sans-serif;
    font-family: 'Martel Sans', sans-serif;
    font-size: 15px;
    color: $font-color;
    background-color: #000;
    letter-spacing: 0.5px;
    line-height: 150%;
    //user-select: none; wtf
}

h1, h2, h3 {
    font-family: "Aller", sans-serif;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: $primary-color;

    &:hover {
        color: $font-color;
    }
}

.content {
    padding: $base-spacing * 1.5 0;
    margin-bottom: $base-spacing * 1.5;

    &--light {
        background-color: $light-grey-background;
    }

    &--middle {
        background-color: $middle-grey-background;
    }

    &--dark {
        background-color: $dark-grey-background;
    }

    &--relative {
        position: relative;
        padding: 0;
        //margin-bottom: 0;
    }

    & > div:last-of-type {
        margin-bottom: 0;
    }
}

.fullslider {
    .flickity-page-dots {
        bottom: $base-spacing / 2;
    }
    img {
        width: 100vw; //percent width is buggy but viewport width works fine
        height: auto;
        display: block;
    }
}

//needs to be more specific then the default styles
//only change the dots of the ticketslider
.ticketslider {
    /* position dots up a bit */
    .flickity-page-dots {
        bottom: -19px;
    }
    /* dots are lines */
    .flickity-page-dots .dot {
        height: 2px;
        border-radius: 0;
        width: 80px;
        background-color: $font-color;

        margin: 0 $base-spacing / 2 0 $base-spacing / 2;

        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
        &:hover {
            background: $primary-color;
        }

        &.is-selected {
            background: $primary-color;
        }
    }
}

.upbutton {
    background-color: #000;
    border: 1px solid $dark-grey-background;

    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 20px;

    display: flex;
    justify-content: center;
    align-items: stretch;

    a {
        flex: 1;
        width: 100%;
        text-align: center;
        line-height: 50px;
        font-size: 2em;
        color: #fff;

        &:hover {
            color: $primary-color;
        }
    }
}

//scrollbar stylings
.ps__thumb-y {
  background: $primary-color;
}

.postponed-text {
    font-weight: bold;
    color: red;
}

// new blocks
@import "PodcastPlayer";

//blocks-legacy
@import "blocks/slider";
@import "blocks/auflockerung";
@import "blocks/auflockerung-zitat";
@import "blocks/text";
@import "blocks/headlines";
@import "blocks/image-text";
@import "blocks/text-image";
@import "blocks/livestream";
@import "blocks/event-slider";
@import "blocks/event-news";
@import "blocks/ticket-slider";
@import "blocks/event-boxen";
@import "blocks/footer";
@import "blocks/copyright";
@import "blocks/tickets";
@import "blocks/gallery";
@import "blocks/video-gallery";
@import "blocks/sponsoring";
@import "blocks/ticket-overview";
@import "blocks/two-columned-text";
@import "blocks/dividing-line";
@import "blocks/sponsoring-references";
@import "blocks/team";
@import "blocks/milestone";
@import "blocks/touren-banner";
@import "blocks/navigation";
@import "blocks/presented-banner";
@import "blocks/detail-banner";
@import "blocks/main-sponsors-slider";
@import "blocks/event-series-banner";
@import "blocks/event-infos";
@import "blocks/small-sonsor-banner";
@import "blocks/google-maps";
@import "blocks/detail-content";
@import "blocks/archive-banner";
@import "blocks/archive-list";
@import "blocks/archive";
@import "blocks/menu";
@import "blocks/downloads";
@import "blocks/news-details";
@import "blocks/contact";
@import "blocks/linked-images";
@import "blocks/wysiwyg";
@import "blocks/longines";
@import "blocks/search-result";
@import "blocks/video";
@import "blocks/social-media";
@import "blocks/services";
@import "blocks/medien-center";