@import "../vars";
@import "../mixins";

.menu {
  opacity: 0;
  display: none;
  position: fixed;
  height: calc(100vh - #{$nav-height});
  top: $nav-height;
  background-color: $default-background;
  color: $font-color;
  width: 100%;
  left: 0;
  right: 0;
  padding: $base-spacing;

  @include styleBelow(768px) {
    padding-top: 0;
  }

  &__inner {
    display: block;
    flex: 1;
    padding: $base-spacing / 2;
    text-align: right;
    position: relative;

    @include styleBelow(1350px) {
      padding-left: $base-spacing * 2;
      padding-right: 0;
    }

    @include styleBelow(1024px) {
      padding-left: $base-spacing;
    }

    @include styleBelow(768px) {
      padding-bottom: $base-spacing;
      padding-top: 0;
      flex: none;
    }

    &:first-of-type {
      border-right: 2px solid $primary-color;
      overflow-y: hidden;
      padding-right: $base-spacing * 2;

      @include styleBelow(1350px) {
        padding-left: 0;
      }

      @include styleBelow(1024px) {
        padding-left: 0;
        padding-right: $base-spacing;
      }

      // Evtl. den border schon eher ausblenden, damit Scrollbalken schicker wirkt?
      @include styleBelow(768px) {
        border-right: none;
        padding-right: $base-spacing;
      }

      @include styleBelow(375px) {
        padding-right: $base-spacing/3;
      }
    }

    &--2x {
      flex: 2;
    }

    &--mobile-order1 {

      @include styleBelow(768px) {
        order: 1;
      }
    }

    &--mobile-order2 {
      @include styleBelow(768px) {
        order: 2;
      }
    }
  }

  &__search {
    display: flex;
    justify-content: flex-end;
    max-height: 0;
    overflow: hidden;
    transition: max-height 150ms ease-out;

    &--active {
      max-height: 41px;
      transition: max-height 150ms ease-out;
    }
  }

  &__search-input {
    background-color: $font-color;
    border: none;
    padding: $base-spacing/3 $base-spacing;
    font-size: 1.2em;
    color: $middle-grey-background;

    @include styleBelow(900px) {
      padding: $base-spacing/3 $base-spacing/18;
    }

    @include styleBelow(768px) {
      padding: $base-spacing/3 $base-spacing/1.5;
    }
  }

  &__submit {
    background-color: $primary-color;
    padding: $base-spacing/4 $base-spacing;
    border: none;
    color: $font-color;
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: lighter;
    cursor: pointer;

    @include styleBelow(900px) {
      padding: $base-spacing/4 $base-spacing/3;
      font-size: 1em;
    }

    @include styleBelow(768px) {
      padding: $base-spacing/4 $base-spacing;
      font-size: 1.1em;
    }

    @include styleBelow(400px) {
      padding: $base-spacing/4 $base-spacing/3;
      font-size: 1em;
    }

    &:hover {
      background-color: $light-grey;
      color: $font-color;
    }
  }

  &__active {
    display: flex;
    opacity: 1;
    z-index: 10000;

    @include styleBelow(768px) {
      flex-flow: column;
    }
  }

  &__item {
    text-transform: uppercase;
    margin-bottom: $base-spacing/1.5;
    font-size: 1.5em;
    transition: 400ms transform;

    a {
      color: $font-color;

      &:hover {
        color: $primary-color;
      }
    }

    &--active {

      .menu__submenu {
        display: block;

        &--images {
          @include styleBelow(1842px) {
            display: flex;
            flex-wrap: wrap;
          }
        }

      }

      //todo here
      .fa-chevron-left {
        transition: 400ms transform;
        transform: rotate(-90deg);
      }

      a:not(.menu__sublink) {
        color: $primary-color;
      }
    }

    &--images {
      @include styleBetween(461px, 1842px) {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
      }
    }

  }

  &__link {

  }

  &__submenu {
    display: none;
    margin-top: $base-spacing/3;

    &--images {
      display: block;

      @include styleBelow(1842px) {
        max-width: 553px;
        display: flex;
        flex-wrap: wrap;
      }

      @include styleBelow(460px) {
        max-width: 100%;
      }
    }

  }

  &__sublink {
    font-size: 0.75em;
    //margin-bottom: $base-spacing/3;
    font-weight: lighter;
    display: block;
    line-height: 150%;
    margin-right: 22px;

    @include styleBelow(1838px) {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
    }

    &--img {
      display: inline-block;
      margin-right: 0;

      @include styleBetween(461px, 1842px) {
        @include gridItem(100%, 3, $base-spacing / 4);
      }

      @include styleBelow(460px) {
        @include gridItem(100%, 2, $base-spacing / 4);
      }

    }
  }

  &__sublink-img {

    img {
      display: block;
      width: 100%;
    }
  }

  //Scrollbar Styling - https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp
  //
  //::-webkit-scrollbar {
  //  width: 6px;
  //}
  //
  //::-webkit-scrollbar-track {
  //  background: #000;
  //}
  //
  //::-webkit-scrollbar-thumb {
  //  background: #00b2a9;
  //}
  //
  //::-webkit-scrollbar-thumb:hover {
  //  background: #fff;
  //}
}