@import "vars";

// apply styles only if viewport width is below $width
@mixin  styleBelow($width) {
    @media (max-width: $width) {
        @content;
    }
}

@mixin  styleAbove($width) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin  styleBetween($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin container($size: $container-size) {
    max-width: $size;
    margin: 0 auto;
}

@mixin grid() {
    display: flex;
    flex-wrap: wrap;
}

@mixin gridItem($width, $images-per-line, $spacing) {
    width: calc(#{$width / $images-per-line} - #{$spacing * ($images-per-line - 1) / $images-per-line});
    margin-bottom: $spacing;

    margin-right: $spacing;
    &:nth-child(#{$images-per-line}n + 0) {
        margin-right: 0;
    }
}

@mixin landingGridItem($width, $images-per-line, $spacing) {

    @include gridItem($width, $images-per-line, $spacing);
    height: calc(#{$width / $images-per-line} - #{$spacing * ($images-per-line - 1) / $images-per-line});
}

@mixin centerItem($width, $images-per-line, $spacing) {
    width: calc(#{$width / $images-per-line} - #{$spacing * ($images-per-line - 1) / $images-per-line});
    margin-bottom: $spacing;

    margin-right: $spacing / 2;
    margin-left: $spacing / 2;


    &:nth-child(#{$images-per-line}n + 1) {
        margin-left: 0;
    }

    &:nth-child(#{$images-per-line}n + 0) {
        margin-right: 0;
    }

    &:last-of-type{
        margin-right: 0;
    }
}