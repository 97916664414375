@import "../vars";
@import "../mixins";

$light-background-color: #fbfbfb;

.main-sponsors-slider-wrapper {
    //background-color: $light-background-color;
    background-color: #fff;
    border-top: 5px solid $primary-color;
    margin-bottom: $base-spacing*1.5;

    &--no-bottom-space {
        margin-bottom: 0px;
    }

}

.main-sponsors-slider {
    @include container($container-size);
    display: flex;
    justify-content: center;

    @include styleBelow(1550px) {
        //padding: 0 $base-spacing;
    }

    @include styleBelow(768px) {
        @include grid();
        justify-content: center;
    }

    @include styleBelow(425px) {
        padding: 0 $base-spacing/2;
    }

    &--small {
        @include container(1275px);
    }

    &__logo {
        //padding-top: $base-spacing;
        //padding-bottom: $base-spacing;
        margin-right: $base-spacing*2;

        @include styleBelow(1300px) {
            margin-right: $base-spacing/4;
        }

        @include styleBelow(768px) {
            padding-top: 0;
            padding-bottom: 0;
            margin-right: 0;
        }

        @include styleBelow(600px) {
            width: 33%;
            margin-right: 0px;

            &:nth-of-type(2n) {
                margin-right: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        img {
            display: block;
            width: 100%;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}
