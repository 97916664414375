@import "../vars";
@import "../mixins";

.detail-banner {
  position: relative;

  &__img {
    display: block;
    width: 100%;
  }

  &__spacer {
    flex: 1;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @include styleBelow(850px){
      position: inherit;
    }
  }

  &__content-wrapper {
    @include container($container-size);
    width: 1500px;
    display: flex;

    @include styleBelow(1550px){
      padding: 0 $base-spacing;
    }

    @include styleBelow(850px) {
      padding: $base-spacing $base-spacing;
    }

    @include styleBelow(425px) {
      padding:  $base-spacing $base-spacing/2;
    }
  }

  &__livestream {

      @include styleBelow(960px){
          margin-right: $base-spacing;
      }
  }

  &__livestream-logo {
    margin-bottom: $base-spacing/2;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.70);

    @include styleBelow(1000px) {
      margin-bottom: 5px;
    }
  }

  &__livestream-buttons-big-wrapper {

    @include styleBelow(850px){
      display: flex;
    }

    @include styleBelow(600px){
      display: block;
    }
  }

  &__livestream-button-wrapper {
    margin-bottom: $base-spacing/1.5;

    @include styleBelow(1000px) {
      margin-bottom: 10px;
    }

    @include styleBelow(850px) {
      margin-bottom: 0;
    }
  }

  &__livestream-buttons-wrapper {
    display: flex;

    @include styleBelow(600px){
      margin-top: $base-spacing/3;
    }
  }

  &__livestream-button {

    &--primary-accent {
      transition: $hover-transition-time;
      background-color: $primary-color;
      padding: $base-spacing/4 $base-spacing;
      border: 3px solid $primary-color;
      font-size: 1.1em;
      text-transform: uppercase;
      color: $font-color;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.70);


      @include styleBelow(1000px) {
        font-size: 0.9em;
      }

      @include styleBelow(850px) {
        margin-right: 5px;
        padding: $base-spacing/5 $base-spacing/2;
      }

      @include styleBelow(600px){
        margin-right: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0);
        color: $font-color;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.80);
        transition: $hover-transition-time;
      }
    }

    &--red {
      transition: $hover-transition-time;
      background-color: $button-color;
      padding: $base-spacing/4 $base-spacing;
      border: 3px solid $button-color;
      font-size: 1.1em;
      text-transform: uppercase;
      color: $font-color;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.70);


      @include styleBelow(1000px) {
        font-size: 0.9em;
      }

      @include styleBelow(850px) {
        margin-right: 5px;
        padding: $base-spacing/5 $base-spacing/2;
      }

      @include styleBelow(600px){
        margin-right: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0);
        color: $font-color;
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.80);
          transition: $hover-transition-time;
      }
    }

    &--accent {
      transition: $hover-transition-time;
      background-color: $primary-color;
      padding: 8px 12px;
      border: 3px solid $primary-color;
      font-size: 0.9em;
      text-transform: uppercase;
      color: $font-color;
      opacity: 0.8;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.70);
      margin-right: 5px;



      @include styleBelow(1000px) {
        font-size: 0.8em;
        margin-right: 5px;

        &:last-of-type {
          margin: 0;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0);
        color: $font-color;
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.80);
          transition: $hover-transition-time;
      }
    }
  }

  &__livestream-icon {
    margin-left: 15px;
    height: 25px;
  }

  &__text-wrapper {

    @include styleBelow(1400px){
       padding-top: $base-spacing;
     }

    @include styleBelow(1200px){
      padding-top: $base-spacing*2;
    }

    @include styleBelow(1000px) {
      padding-top: $base-spacing*3
    }

    @include styleBelow(850px) {
      display: none;
    }
  }

  &__text {
      background-color: rgba(220, 220, 220, 0.45);
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.40);
      padding: $base-spacing;

      @include styleBelow(960px){
          padding: $base-spacing/2;
      }
  }

  &__big-text {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 2.1em;
      text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.80);
      color: $font-color;
    margin: 0;
    line-height: 120%;
    border-bottom: 1px solid $font-color;
    padding-bottom: $base-spacing/4;
    margin-bottom: $base-spacing/3;

    @include styleBelow(1200px){
      font-size: 1.7em;
    }

      @include styleBelow(960px){
          font-size: 1.35em;
      }
  }

  &__small-text {
    color: $font-color;
    font-size: 1.5em;
    font-weight: lighter;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.80);

    @include styleBelow(1200px){
      font-size: 1.1em;
    }
  }

  &__button {
    display: flex;
  }

  &__spacer {
    flex: 1;
  }

  &__ticket-button {
    color: $font-color;
    transition: $hover-transition-time;
    border: 3px solid $primary-color;
    margin-top: $base-spacing/2;
    background-color: $primary-color;
    padding: $base-spacing/5 $base-spacing/3;
    text-transform: uppercase;
    font-weight: lighter;
    letter-spacing: 0.1em;
    opacity: 0.8;
    font-size: 0.9em;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.70);

    @include styleBelow(1200px){
      padding: $base-spacing/8 $base-spacing/4;
      font-size: 0.85em;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0);
      color: $font-color;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.70);
        transition: $hover-transition-time;
    }
  }
}
