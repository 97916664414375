@import "../vars";
@import "../mixins";


.headlines {
  @include container($container-size);
  margin-bottom: $base-spacing*1.5;
  text-transform: uppercase;
    user-select: none;

  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  &__wrapper {
    display: flex;
  }

  &__spacer {
    flex: 1;
  }


  h1 {
    color: $font-color;
    font-size: 2.0em;
    margin: $base-spacing/2 0 0 0;
    font-weight: normal;
    line-height: 120%;
    border-bottom: 2px solid $primary-color;
    padding-bottom: $base-spacing/6;

    @include styleBelow(1300px){
      font-size: 1.7em;
    }

    @include styleBelow(800px){
      font-size: 1.4em;
    }

    @include styleBelow(425px){
      font-size: 1.3em;
    }
  }

  h2 {
    font-size: 1.8em;
    font-weight: normal;
    line-height: 140%;
    border-bottom: 2px solid $primary-color;
    margin: $base-spacing/2 0 0 0;

    @include styleBelow(1300px){
      font-size: 1.5em;
    }

    @include styleBelow(800px){
      font-size: 1.3em;
    }

    @include styleBelow(425px){
      font-size: 1.2em;
    }
  }

  h3 {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 130%;
    border-bottom: 2px solid $primary-color;
    margin: $base-spacing/2 0 0 0;

    @include styleBelow(1300px){
      font-size: 1.3em;
    }

    @include styleBelow(800px){
      font-size: 1.1em;
    }

    @include styleBelow(425px){
      font-size: 1em;
    }
  }
}
