@import "../vars";
@import "../mixins";

.sponsoring-references {
  $transition-time: all 500ms;

  @include container($container-size);
  @include grid();
  margin-bottom: $base-spacing*1.5;

  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(500px) {
    padding: 0 $base-spacing/2;
  }

  &__logo {
    @include gridItem(100%, 4, $base-spacing/3);
    overflow: hidden;

    @include styleBelow(1024px){
      @include gridItem(100%, 3, $base-spacing/3);


      &:nth-of-type(4n) {
        margin-right: $base-spacing/3;
      }

      &:last-of-type{
        margin-right: 0;
      }
    }

    @include styleBelow(750px) {
      @include gridItem(100%, 2, $base-spacing/3);

      &:nth-of-type(odd) {
        margin-right: $base-spacing/3;
      }

      &:last-of-type{
        margin-right: 0;
      }
    }

    @include styleBelow(375px) {
      @include gridItem(100%, 1, $base-spacing/3);

      &:last-of-type {
        margin-bottom: 0;
      }
    }


    img {
      display: block;
      width: 100%;
      transition: $transition-time;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}


.detail-content {
  $detail-container-size: 1000px;
  $transition-time: all 500ms;

  &__sponsoring-references {
    @include container($detail-container-size);
    @include grid();
    margin-bottom: $base-spacing*1.5;
  }

  &__logo {
    @include gridItem(100%, 3, $base-spacing/3);
    overflow: hidden;

    @include styleBelow(950px){
      @include gridItem(100%, 2, $base-spacing/3);

      &:nth-of-type(odd){
        margin-right: $base-spacing/3;
      }
    }

    @include styleBelow(375px){
      @include gridItem(100%, 1, $base-spacing/3);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      transition: $transition-time;

      &:hover {
        transform: scale(1.2) rotate(-5deg);
      }
    }
  }
}