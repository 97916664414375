@font-face {
    font-family: 'Aller';
    src: url('/static/fonts/Aller_Std_Rg.ttf') format('truetype'),
        url('/static/fonts/Aller-Regular.woff2') format('woff2'),
        url('/static/fonts/Aller-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aller';
    src: url('/static/fonts/Aller_Std_BdIt.ttf') format('truetype'),
        url('/static/fonts/Aller-BoldItalic.woff2') format('woff2'),
        url('/static/fonts/Aller-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Aller';
    src: url('/static/fonts/Aller_Std_It.ttf') format('truetype'),
        url('/static/fonts/Aller-LightItalic.woff2') format('woff2'),
        url('/static/fonts/Aller-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aller';
    src: url('/static/fonts/Aller_Std_It.ttf') format('truetype'),
    url('/static/fonts/Aller-Italic.woff2') format('woff2'),
        url('/static/fonts/Aller-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Aller';
    src: url('/static/fonts/Aller_Std.ttf') format('truetype'),
        url('/static/fonts/Aller-Bold.woff2') format('woff2'),
        url('/static/fonts/Aller-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aller';
    src: url('/static/fonts/Aller_Std_Lt.ttf') format('truetype'),
        url('/static/fonts/Aller-Light.woff2') format('woff2'),
        url('/static/fonts/Aller-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
