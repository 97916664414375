@import "../vars";
@import "../mixins";

.archive-banner {
  //margin-bottom: $base-spacing*1.5;
  position: relative;

  &__img {
    width: 100%;
    display: block;
  }


  &__text-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
      overflow:hidden;
  }

  &__position-wrapper {
    display: flex;
    //width: 100%;

      @include styleBelow(425px){
          display: none;
      }
  }

    &__center-text {
        background-color: rgba(220, 220, 220, 0.45);
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.40);
        padding: $base-spacing;
    }

  &__text {
      //flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }

  &__end {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $font-color;
    transition: $hover-transition-time;
    min-width: 113px;

      text-shadow: 3px 3px 10px rgba(0,0,0,1.00);
      svg {
          filter: drop-shadow( 3px 3px 10px rgba(0,0,0,1.00));
      }

    &:hover {
      color: $primary-color;
    }

    &--left {
      padding-right: $base-spacing;

      @include styleBelow(550px){
        padding-left: $base-spacing/2;
      }

      @include styleBelow(350px){
        padding-left: $base-spacing/3;
      }
    }

    &--right {
      padding-left: $base-spacing;

      @include styleBelow(550px){
        padding-right: $base-spacing/2;
      }

      @include styleBelow(350px){
        padding-right: $base-spacing/3;
      }
    }


      @include styleBelow(768px){
          padding-left: 5px;
      }
  }

  &__icon {
    display: flex;
    justify-content: center;
    margin-top: $base-spacing/3;
      font-size: 3em;

    @include styleBelow(768px){
      font-size: 1.4em;
    }

    @include styleBelow(700px){
      font-size: 1.2em;
    }

    @include styleBelow(425px){
      font-size: 1em;
      margin-top: 0;
    }
  }


  &__small-year {
    display: flex;
    justify-content: center;
    font-size: 3em;
      padding-top: $base-spacing/1.5;

    @include styleBelow(768px){
      font-size: 1.4em;
    }

    @include styleBelow(700px){
      font-size: 1.2em;
    }

    @include styleBelow(425px){
      font-size: 1em;
    }
  }

  &__year {
    font-size: 2.8em;
    color: $font-color;
    text-align: center;
      text-shadow: 3px 3px 10px rgba(0,0,0,.8);
      line-height: 120%;

    @include styleBelow(1750px){
      font-size: 3.3em;
    }

    @include styleBelow(1024px){
      font-size: 3em;
    }

    @include styleBelow(900px){
      font-size: 2.7em;
    }

    @include styleBelow(700px){
      font-size: 2.3em;
    }


    @include styleBelow(550px){
      font-size: 2em;
    }

    @include styleBelow(425px){
      font-size: 1.5em;
    }

    @include styleBelow(350px){
      font-size: 1.3em;
    }
  }

  &__big-text {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 2.5em;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.68);
    margin: 0;
    line-height: 120%;
    border-bottom: 1px solid $font-color;
    padding-bottom: $base-spacing/2;
    margin-bottom: $base-spacing;
      text-align: center;

    @include styleBelow(1750px){
      font-size: 2.1em;
    }

    @include styleBelow(1180px){
      bottom: $base-spacing;
    }

    @include styleBelow(1024px){
      font-size: 2em;
    }

    @include styleBelow(900px){
      font-size: 1.7em;
    }

    @include styleBelow(700px){
      font-size: 1.5em;
      margin-bottom: $base-spacing/2;
      padding-bottom: $base-spacing/3;
    }

    @include styleBelow(550px){
      font-size: 1.3em;
      margin-bottom: $base-spacing/3;
      padding-bottom: $base-spacing/4;
    }

    @include styleBelow(425px){
      font-size: 1.1em;
    }

    @include styleBelow(350px){
      font-size: 1em;
      margin-bottom: $base-spacing/4;
      padding-bottom: $base-spacing/5;
    }
  }

  &__winners {
    position: absolute;
    bottom: $base-spacing*2;
    right: $base-spacing*12;
    display: flex;

    @include styleBelow(1750px){
      right: $base-spacing*7;
    }

    @include styleBelow(1280px){
      right: $base-spacing*4;
    }

    @include styleBelow(1180px){
      bottom: $base-spacing;
    }

    @include styleBelow(1075px){
      bottom: $base-spacing/1.5;
    }

    @include styleBelow(1024px){
      display: none;
    }
  }

  &__winner-icon {
    font-size: 2em;
    margin-right: $base-spacing/2;

    @include styleBelow(1750px){
      font-size: 1.7em;
    }

    @include styleBelow(1280px){
      font-size: 1.4em;
    }
  }

  &__winner {
    margin-bottom: $base-spacing/2;
    text-transform: uppercase;

    @include styleBelow(1075px){
      margin-bottom: $base-spacing/3;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &--first {
      font-size: 2em;

      @include styleBelow(1750px){
        font-size: 1.7em;
      }

      @include styleBelow(1280px){
        font-size: 1.4em;
      }
    }

    &--second {
      font-size: 1.4em;
      margin-bottom: 10px;

      @include styleBelow(1750px){
        font-size: 1.3em;
      }

      @include styleBelow(1280px){
        font-size: 1.2em;
      }

      @include styleBelow(1075px){
        margin-bottom: $base-spacing/4;
      }
    }

    &--third {
      font-size: 1.2em;

      @include styleBelow(1750px){
        font-size: 1.1em;
      }

      @include styleBelow(1280px){
        font-size: 1em;
      }
    }
  }
}