@import "../vars";
@import "../mixins";

//responsive gmap
.google-maps {
  margin-bottom: $base-spacing*1.5;
  position: relative;
  padding-bottom: 30%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}