@import "../vars";
@import "../mixins";

.team {
  $transition-time: all 500ms;
  //margin-bottom: $base-spacing*1.5;

  &__team-picture {
    margin-bottom: $base-spacing;

    img {
      width: 100%;
      display: block;
    }
  }

  &__text {
    @include container($container-size);
    margin-bottom: $base-spacing;

    @include styleBelow(1550px){
      padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
      padding: 0 $base-spacing/2;
    }
  }

  &__item-wrapper {
    @include grid();
    @include container($container-size);

    @include styleBelow(1550px){
      padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
      padding: 0 $base-spacing/2;
    }
  }

  &__item {
      @include styleAbove(1301px) {
          @include gridItem (100%, 4, $base-spacing);
          margin-bottom: $base-spacing*1.5;
      }


    @include styleBetween(1151px,1300px) {
      @include gridItem (100%, 4, $base-spacing/2);
      margin-bottom: $base-spacing*1.5;
    }

    @include styleBetween(1025px,1150px) {
      @include gridItem (100%, 3, $base-spacing);
      margin-bottom: $base-spacing*1.5;

      &:nth-of-type(3n+0){
          margin-right: 0; //mixins: &:nth-child(#{$images-per-line}n + 0) (???)
      }
    }

    @include styleBetween(871px,1024px) {
      @include gridItem (100%, 3, $base-spacing/2);
      margin-bottom: $base-spacing*1.5;

      &:nth-of-type(3n){
        margin-right: 0;
      }
    }

    @include styleBetween(576px,870px) {
      @include gridItem (100%, 2, $base-spacing/2);
      margin-bottom: $base-spacing*1.5;

      &:nth-of-type(even){
        margin-right: 0;
      }

    }

    @include styleBetween(320px,575px) {
      @include gridItem (100%, 1, $base-spacing/2);
      margin-bottom: $base-spacing*1.5;

    }
  }

  &__picture {
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      transition: $transition-time;

      &:hover {
        transform: scale(1.2);
      }

    }
  }

  h4 {
    color: $light-turquoise;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.1em;
    margin-bottom: 0;

    @include styleBelow(760px){
      font-size: 1em;
    }
  }

  &__job {
    font-weight: lighter;
    margin-bottom: $base-spacing/2;
  }

  &__number {
    margin-bottom: $base-spacing/2;
    font-weight: lighter;
  }

  &__contact-info {
    color: $light-turquoise;
    font-weight: lighter;
  }

  &__mail-address {

    a {
      color: $font-color;
      font-weight: lighter;
      transition: $hover-transition-time;

      &:hover {
        color: $light-turquoise;
      }
    }
  }
}