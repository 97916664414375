.primary-item {
    border: solid #00b2a9 8px;
    background-color: white;
    color: #00b2a9;
    font-weight: bolder;
}

.secondary-item {
    border: solid #00b2a9 8px;
    background-color: #00b2a9;
    color: white;
    font-weight: bolder;
}

.primary-link {
    border: solid #00b2a9 4px;
    background-color: #00b2a9;
    color: white;
    font-weight: bolder;
    transition: 0.4s;
}

.secondary-link {
    border: solid white 2px;
    background-color: #00b2a9;
    color: white;
    font-weight: bolder;
    transition: 0.4s;
}

.third-item {
    grid-column: span 1 / span 1;
    width: 100%;
}

.primary-link:hover {
    border: solid #00b2a9 4px;
    background-color: white;
    color: #00b2a9;
    font-weight: bolder;
    transition: 0.4s;
}

.secondary-link:hover {
    border: solid white 2px;
    background-color: white;
    color: #00b2a9;
    font-weight: bolder;
    transition: 0.4s;
}

.services-container {
    @include container($container-size);
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
    padding: 2rem;
    .service-item {
        height: 20rem;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
        align-items: center;
        justify-items: center;
        .service-headline {
            text-align: center;
            text-transform: uppercase;
            font-size: 3rem;
            line-height: 3.5rem;
            padding: 0 1rem;
        }
        .service-link {
            display: flex;
            justify-content: center;
            justify-self: center;
            align-items: center;
            padding: 0.5rem 0.5rem 0.25rem 0.5rem;
            .service-link-text {
                text-transform: uppercase;
                font-size: large;
                overflow: hidden;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    padding: 0 30px;
    a {
        color: white;
    }
}

@media (min-width: 768px) {
    .services-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .third-item {
        grid-column: span 2 / span 2;
        margin: auto;
        width: 48%;
    }
}

@media (min-width: 1551px) {
    .services-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 0 0;
    }
    .third-item {
        grid-column: span 1 / span 1;
        width: 100%;
    }
}