@import "../vars";
@import "../mixins";

.news-details {
    @include container($container-size * 0.85);

    @include styleBelow(1550px) {
        padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
        padding: 0 $base-spacing/2;
    }


    &__navigation {
        display: flex;
        font-size: 1.3em;
        text-transform: uppercase;
        margin-top: $base-spacing / 2;
        margin-bottom: $base-spacing / 2;
    }

    &__spacer {
        flex: 1;
    }

    &__back {
        text-transform: uppercase;
        text-align: left;
        font-size: 1.3em;
        color: $font-color;
        transition: $hover-transition-time;

        @include styleBelow(768px) {
            font-size: 1.1em;
            margin-bottom: $base-spacing/2;
        }

        @include styleBelow(375px) {
            font-size: 1em;
        }

        &:hover {
            color: $primary-color;
        }

        .svg-inline--fa {
            margin-right: 5px;
        }
    }

    &__date {
        color: $primary-color;
    }

    &__headline {
        text-transform: uppercase;
        margin-top: 5px;
        line-height: 115%;
    }

    &__img {
        float: left;
        max-width: 500px;
        padding-right: $base-spacing / 3;
        padding-bottom: $base-spacing / 3;

        img {
            display: block;
            width: 100%;
        }

        @include styleBelow(570px) {
            width: 100%;
            padding-right: 0px;
        }
    }

    &__imginfotext {
        display: inline-block;
        margin-top: 5px;
        font-style: italic;
    }

    &__banner {
        width: 100%;
        display: block;
        margin-bottom: $base-spacing /2;
    }
}
