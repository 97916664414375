@import "../vars";
@import "../mixins";

.milestone {
  @include container($container-size);
  display: flex;
  //margin-bottom: $base-spacing*1.5;


  &__item {
    flex: 1;
    flex-flow: row;
    margin-right: $base-spacing*2;
    position: relative;
  }

  &__circle-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom:0;
  }

  &__circle {
    background-color: $primary-color;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin-bottom: $base-spacing;
  }

  &__text {
    line-height: 150%;
    font-weight: lighter;
  }

  &__year {
    position: absolute;
    font-size: 2.2em;
    font-weight: lighter;
  }
}