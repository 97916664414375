@import "../vars";
@import "../mixins";

.slider {
    position: relative;
    margin-bottom: $base-spacing*1.5;

    &__text-wrapper {
        @include container($container-size);
        @include styleBelow($container-size) {
            margin-left: 15px;
        }

        @include styleBelow(950px) {
            display: none;
        }
    }

    &__text {
        display: none;
        position: absolute;
        //bottom: $base-spacing*10;
        top: calc(50% - 110px);

        background-color: rgba(220, 220, 220, 0.45);
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.40);
        padding: $base-spacing;
    }

    &__big-text {
        display: block;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 2.5em;
        text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.80);
        margin: 0;
        line-height: 120%;
        border-bottom: 1px solid $font-color;
        padding-bottom: $base-spacing/4;
        margin-bottom: $base-spacing/3;
        color: $font-color;

        &:hover {
            color: $primary-color;
            transition: $hover-transition-time;
        }
    }

    &__small-text {
        color: $font-color;
        font-size: 1.5em;
        font-weight: lighter;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.80);
    }

    &__button {
        display: flex;
    }

    &__spacer {
        flex: 1;
    }

    &__ticket-button {
        border: 3px solid $primary-color;
        margin-top: $base-spacing;
        background-color: $primary-color;
        padding: $base-spacing/5 $base-spacing/3;
        text-transform: uppercase;
        font-weight: lighter;
        letter-spacing: 0.1em;
        opacity: 0.8;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.70);
        color: $font-color;

        &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: $font-color;
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.80);
            transition: $hover-transition-time;
        }
    }
}
