@import "vars";

$breakpoint: 700px;

.PodcastPlayer {

    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: $base-spacing;
    padding: 0 $base-spacing;

    display: flex;

    @include styleBelow($breakpoint) {
        flex-direction: column;
    }

    &__Frame {
        flex: 1;

        @include styleBelow($breakpoint) {
            margin-bottom: $base-spacing;
        }
    }

    &__Description {
        flex: 1;
        padding-left: $base-spacing;

        @include styleBelow($breakpoint) {
            padding-left: 0;
        }

        p {
            margin-top: 0;
        }
    }
}
