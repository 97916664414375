@import "../vars";
@import "../mixins";

.ticket-overview {
  background-color: $light-grey-background;
  margin-bottom: $base-spacing*1.5;

  &__content {
    display: flex;
    flex: 1;

    @include styleBelow(800px) {
      flex-flow: column;
      width: auto;
    }
  }

  &__events {
    @include container($container-size);
    padding-top: $base-spacing*1.5;
    padding-bottom: $base-spacing*1.5;
    padding-right: $base-spacing*1.5;


    @include styleBelow(1550px){
      padding: $base-spacing $base-spacing;
    }

    @include styleBelow(425px) {
      padding: $base-spacing $base-spacing/2;
    }
  }

  &__infos {
    display: flex;
    flex-flow: column;
    margin-left: $base-spacing;
    margin-top: 5px;

    @include styleBelow(800px) {
      margin-left: 0;
      margin-top: $base-spacing/2;
    }
  }

  &__headline {
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 5px;
    font-weight: normal;

    a {
      color: $light-turquoise;
      transition: $hover-transition-time;

      &:hover {
        color: $font-color;
      }
    }
  }

  &__logo {
    display: block;
  }

  &__subline {
    font-size: 1.1em;
    font-weight: lighter;
    letter-spacing: 0.05em;

    @include styleBelow(375px) {
      font-size: 1em;
    }
  }

  &__available {
    font-size: 1.1em;
    margin-top: $base-spacing/2;
    font-weight: lighter;
    font-style: italic;
    opacity: 0.8;

    @include styleBelow(375px) {
      font-size: 1em;
    }
  }

  &__line {
    border-bottom: 1px solid $font-color;
    margin: $base-spacing 0;

    @include styleBelow(1200px){
      margin-top: $base-spacing/2;
    }
  }

  &__spacer {
    flex: 1;
  }

  &__mobile-wrapper {
    display: flex;
    width: 100%;

    @include styleBelow(800px) {
      flex-flow: column;
      width: auto;
      margin-bottom: $base-spacing/2;
    }

    @include styleBelow(320px) {
      margin-bottom: 0;
    }
  }

  &__button-wrapper {
    margin-top: $base-spacing;

    @include styleBelow(1200px){
      margin-top: 0;
    }

    @include styleBelow(800px) {
      display: flex;
      align-items: flex-start;
    }

    @include styleBelow(490px) {
      flex-flow:column;
    }
  }

  &__grey-button-wrapper {
    display: flex;
    margin-top: $base-spacing/3;

    @include styleBelow(1200px){
      flex-flow: column;
      margin-top: $base-spacing/2;
    }

    @include styleBelow(800px) {
      flex-flow: row;
    }

    @include styleBelow(490px) {
      flex-flow:column;
      margin-top: $base-spacing/3;
    }
  }

  &__detailbutton {
    background-color: $lighter-grey;
    border: 3px solid $lighter-grey;
    margin-right: $base-spacing/3;
    padding: 5px 15px;
    color: $middle-grey-background;
    text-align: center;
    display: block;
    line-height: 100%;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 0.9em;
    transition: $hover-transition-time;

    @include styleBelow(1200px){
      margin-bottom: $base-spacing/4;
      text-align: center;
    }

    @include styleBelow(800px){
      margin-bottom: 0;
      text-align: center;
    }

    @include styleBelow(750px) {
      font-size: 0.85em;
      padding: 3px 8px;
    }

    @include styleBelow(490px) {
      margin-right: 0;
      margin-bottom: $base-spacing/3;
      padding: 3px 24px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0);
      color: $font-color;
    }
  }

  &__ticketbutton {
    background-color: $primary-color;
    border: 3px solid $primary-color;
    padding: $base-spacing/4 $base-spacing*2;
    color: $font-color;
    text-transform: uppercase;
    font-weight: normal;
    white-space: nowrap;
    letter-spacing: 0.05em;
    text-align: center;
    display: block;
    line-height: 100%;
    font-size: 1em;
    transition: $hover-transition-time;
    margin-bottom: $base-spacing/3;

    @include styleBelow(1200px){
      padding: $base-spacing/4 $base-spacing/2;
      font-size: 0.9em;
    }


    @include styleBelow(800px) {
      margin-top: $base-spacing/2;
      margin-bottom: 0;
      padding: 5px 15px;
      margin-right: $base-spacing/3;
    }

    @include styleBelow(750px) {
      font-size: 0.85em;
      padding: 3px 8px;
    }

    @include styleBelow(490px) {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0);
      color: $font-color;
    }
  }

  &__further-news-wrapper {
    display: flex;
  }

  &__spacer {
    flex: 1;
  }

  &__further-news {
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: lighter;

    &:hover {
      color: $light-turquoise;
    }

    .arrows {
      color: $light-turquoise;

      &:hover {
        color: $font-color;
      }
    }
  }
}

