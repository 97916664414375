@import "../vars";
@import "../mixins";


.downloads {

    &__item {
        display: flex;
        flex-flow: row;
        color: $font-color;
    }

    &__item-text {
        flex-flow: column;
    }

    &__item-icon {
        display: flex;
        align-items: center;
        padding-right: $base-spacing*1.5;

        .svg-inline--fa {
            font-size: 1.4em;
        }
    }

    &__item-headline {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-size: 1.4em;
        margin-bottom: 5px;
    }

    &__item-date {
        font-size: 1em;
        font-weight: lighter;
        display: flex;
        align-items: center;
    }

    &__spacer {
        flex: 1;
    }

    &__item-description {
        font-weight: lighter;
        display: flex;
        align-items: center;
        font-size: 1em;
    }

    &__line {
        border-bottom: 1px solid $font-color;
        margin: $base-spacing 0;
    }
}