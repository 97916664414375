@import "../vars";
@import "../mixins";

.presented-banner {
  background-color: $primary-color;
  height: $presented-banner-height;
  //margin-bottom: $base-spacing*4.5;


  &__content {
    @include container($container-size);
    position: relative;

    @include styleBelow(1550px) {
      padding: 0 $base-spacing;
    }

    @include styleBelow(600px) {
      padding: 0 $base-spacing/2;
    }

    @include styleBelow(425px) {
      padding: 0 $base-spacing/2;
    }
  }

  &__text {
    padding-top: 25px;
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: lighter;
    color: $font-color;
    margin-top: 0;
    margin-bottom: 0;

    @include styleBelow(1024px) {
      font-size: 1.1em
    }

    @include styleBelow(768px) {
      padding-top: $base-spacing/2;
    }

    @include styleBelow(600px) {
      font-size: 0.9em
    }

    @include styleBelow(425px){
      padding-top: $base-spacing/3;
    }

    @include styleBelow(340px){
      font-size: 0.8em
    }
  }

  &__background-wrapper {
    background-color: transparent;
    height: 156px;

    @include styleBelow(768px) {
      height: 120px;
    }
  }

  &__logo {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;

    @include styleBelow(1550px) {
      right: $base-spacing;
    }

    @include styleBelow(600px) {
      right: $base-spacing/2;
    }

    @include styleBelow(768px) {
      display: flex;
      flex-direction: row-reverse;
    }

    img {
      display: block;
      width: 100%;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, .40);

      @include styleBelow(768px) {
        width: 75%;
        height: 75%;
      }

      @include styleBelow(425px){
        width: 50%;
        height: 50%;
      }
    }
  }
}