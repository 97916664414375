@import "../vars";
@import "../mixins";

.tickets {
  display: flex;
  margin-bottom: $base-spacing*1.5;

  @include styleBelow(1400px){
    flex-flow: column-reverse;
  }

  &__content {
    display: flex;
    flex: 1;

    @include styleBelow(650px) {
      flex-flow: column;
    }
  }

  &__infos {
    display: flex;
    flex-flow: column;
    margin-left: $base-spacing;
    margin-top: 5px;

    @include styleBelow(795px) {
      margin-top: 0;
    }

    @include styleBelow(650px) {
      margin-left: 0;
      margin-top: $base-spacing/2;
    }
  }

  &__headline {
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 5px;
    font-weight: normal;

    @include styleBelow(795px) {
      font-size: 1em;
    }

    @include styleBelow(650px) {
      font-size: 1.2em;
    }

    a {
      color: $light-turquoise;

      &:hover {
        color: $font-color;
      }
    }
  }

  &__logo {
    display: block;
  }

  &__subline {
    font-size: 1.1em;
    font-weight: lighter;
    letter-spacing: 0.05em;


    @include styleBelow(795px) {
      font-size: 1em;
    }

    @include styleBelow(650px) {
      font-size: 1.1em;
    }

    @include styleBelow(375px) {
      font-size: 1em;
    }
  }

  &__available {
    font-size: 1.1em;
    margin-top: $base-spacing/2;
    font-weight: lighter;
    font-style: italic;
    opacity: 0.8;


    @include styleBelow(795px) {
      font-size: 1em;
    }

    @include styleBelow(650px) {
      font-size: 1.1em;
    }

    @include styleBelow(375px) {
      font-size: 1em;
    }
  }

  &__line {
    border-bottom: 1px solid $font-color;
    margin: $base-spacing 0;
  }

  &__spacer {
    flex: 1;
  }

  &__button-wrapper {
    margin-top: $base-spacing*2;

    @include styleBelow(768px) {
      margin-top: $base-spacing/2;
    }
  }

  &__button {
    background-color: $primary-color;
    border: 3px solid $primary-color;
    padding: $base-spacing/4 $base-spacing/2;
    color: $font-color;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.05em;
    font-size: 1em;
    white-space: nowrap;

    transition: $hover-transition-time;

    @include styleBelow(795px) {
      padding: $base-spacing/5 $base-spacing/4;
      font-size: 0.9em;
    }

    @include styleBelow(768px) {
      margin-left: $base-spacing;
    }

    @include styleBelow(650px) {
      margin-left: 0;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0);
      color: $font-color;
      //border: 3px solid $font-color;
    }
  }

  &__further-news-wrapper {
    display: flex;
  }

  &__spacer {
    flex: 1;
  }

  &__further-news {
      text-transform: uppercase;
      font-weight: lighter;
      color: $font-color;
      font-size: 1.1em;

    @include styleBelow(795px) {
      font-size: 1em;
    }

    &:hover {
      color: $light-turquoise;
        transition: $hover-transition-time;
    }

    .arrows {
      color: $light-turquoise;

      &:hover {
        color: $font-color;
      }
    }
  }

  &__events {
    flex: 2;
    background-color: $light-grey-background;
    padding-left: calc((100vw - #{$container-size}) / 2);
    padding-top: $base-spacing*1.5;
    padding-bottom: $base-spacing*1.5;
    padding-right: $base-spacing*1.5;

    @include styleBelow(1550px){
      padding-left: $base-spacing;
    }

    @include styleBelow(425px) {
      padding: $base-spacing $base-spacing/2;
    }
  }

  &__mobile-wrapper {
    display: flex;
    width: 100%;

    @include styleBelow(768px) {
      flex-flow: column;
      width: auto;
      margin-bottom: $base-spacing/2;
    }
  }

  &__information {
    flex: 1;
    background-color: $middle-grey-background;
    padding-right: calc((100vw - #{$container-size}) / 2);
    padding-top: $base-spacing*1.5;
    padding-bottom: $base-spacing*1.5;
    padding-left: $base-spacing*1.5;

    @include styleBelow(1550px){
      padding-right: $base-spacing;
    }

    @include styleBelow(425px) {
      padding: $base-spacing $base-spacing/2;
    }
  }

  &__underline {
    border-bottom: 2px solid $primary-color;
    margin: 5px 0 $base-spacing/2 0;
    width: 40%;
  }

  &__information-headline {
    font-weight: normal;
    margin: 0;
    font-size: 1.5em;
    text-transform: uppercase;
    line-height: 130%;

    @include styleBelow(795px) {
      font-size: 1.3em;
    }
  }

  &__information-item {
    margin-top: $base-spacing/2;
    margin-bottom: $base-spacing;

      &:last-of-type {
          margin-bottom: 0;
      }

    @include styleBelow(1400px){

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  h4 {
    opacity: 0.8;
    font-weight: lighter;
    margin-bottom: 8px;
    font-size: 1em;
  }

  &__information-item-content {
    font-size: 1.5em;
    margin-bottom: 8px;

    @include styleBelow(795px) {
      font-size: 1.3em;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: $font-color;
      transition: $hover-transition-time;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &__information-item-big-wrapper {
    display: block;
    width: 100%;

    @include styleBelow(1400px) {
      display: flex;
    }

    @include styleBelow(650px) {
      flex-flow: column;
    }
  }

  &__information-item-wrapper {
    flex: 1;
  }


  &__information-detail {
    opacity: 0.8;
    font-weight: lighter;
    font-size: 0.9em;
  }

  &__information-detailtext {
    margin-bottom: 8px;
    font-weight: lighter;
    opacity: 0.8;
  }
}


