@import "../vars";
@import "../mixins";


.small-sponsor-banner {
  @include container($container-size);
  margin-bottom: $base-spacing*1.5;


  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  img {
    width: 100%;
    display: block;
  }
}