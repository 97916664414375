@import "../vars";
@import "../mixins";

.footer-bg {
  background-color: $footer-color;
  padding: $base-spacing*1.5 0 $base-spacing*1.5 0;
}

.footer {
  @include container($container-size);
  display: flex;

  @include styleBelow(1550px) {
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  @include styleBelow(768px) {
    display: block;
  }

  &__item-wrapper {
    display: flex;
  }

  &__item-spacer {
    flex: 1;
  }

  &__item {
    flex: 1;

    @include styleBelow(768px) {
      margin-bottom: $base-spacing*2;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__info {
    font-weight: lighter;

    a {
      color: $primary-color;
      transition: $hover-transition-time;

      &:hover {
        color: $font-color;
      }
    }
  }

  &__logo {
    margin-bottom: $base-spacing/2;

    @include styleBelow(768px) {
      margin-bottom: 0;
    }
  }


  h4 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-top: $base-spacing;
    margin-bottom: 0;
    border-bottom: 1px solid $font-color;
    padding-bottom: 10px;

    @include styleBelow(800px) {
      font-size: 1.1em;
    }


    @include styleBelow(768px) {
      margin-top: 0;
    }
  }

  &__item-line {
    border-bottom: 1px solid $font-color;
  }

  &__item-text {
    font-weight: lighter;
    margin-bottom: $base-spacing;
    margin-top: $base-spacing*1.5;
    line-height: 150%;

    @include styleBelow(768px) {
      margin-top: $base-spacing/2;
      margin-bottom: $base-spacing/2;
    }
  }

  p {
    line-height: 70%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    font-weight: 500;
  }

  &__social-media {
    margin-top: $base-spacing*1.5;

    @include styleBelow(768px) {
      margin-top: $base-spacing/2;
    }
  }

  &__social-media-icon {
      margin-bottom: $base-spacing*1.5;

      a {
          font-weight: 300;
          color: $font-color;
          font-style: normal;

          &:hover {
              color: $primary-color;
              transition: $hover-transition-time;
          }
      }

    &:last-of-type {
      margin-bottom: 0;
    }

    @include styleBelow(768px) {
      margin-bottom: $base-spacing;
    }
  }

    .svg-inline--fa {
        font-size: 1.3em;
        margin-right: 10px;
    }

  &__item-registration {
    display: flex;
    font-weight: lighter;
  }

  &__item-email {
    background-color: $font-color;
    color: $dark-grey-background;
    padding: $base-spacing/3 $base-spacing*4 0 0;

    @include styleBelow(1166px) {
      padding: $base-spacing/3 $base-spacing 0 0;
    }

    @include styleBelow(866px) {
      padding: $base-spacing/4 $base-spacing 0 0;
    }

    @include styleBelow(800px) {
      padding: $base-spacing/5 $base-spacing/1.5 0 0;
    }

    @include styleBelow(768px) {
      padding: $base-spacing/3 $base-spacing*4 0 0;
    }

    @include styleBelow(425px) {
      padding: $base-spacing/3 $base-spacing*1.5 0 0;
    }
  }

  &__item-email-text {
    margin-left: $base-spacing/2;
    opacity: 0.8;

    @include styleBelow(1166px) {
      font-size: 0.9em;
    }

    @include styleBelow(866px) {
      font-size: 0.8em;
      margin-left: $base-spacing/4;
    }

    @include styleBelow(768px) {
      margin-left: $base-spacing/2;
      font-size: 0.9em;
    }
  }

  &__item-register {
    background-color: $primary-color;
    color: $font-color;
    padding: $base-spacing/3;
    transition: $hover-transition-time;

    @include styleBelow(866px) {
      padding: $base-spacing/4;
      font-size: 0.9em;
    }

    @include styleBelow(800px) {
      padding: $base-spacing/4;
      font-size: 0.85em;
    }

    @include styleBelow(768px) {
      padding: $base-spacing/3;
      font-size: 0.9em;
    }

    &:hover {
      color: $font-color;
      background-color: $light-grey;
    }
  }
}

