@import "../vars";
@import "../mixins";


.auflockerung {
  margin-bottom: $base-spacing*1.5;

  &__img {
      display: block;
    width: 100%;
  }
}