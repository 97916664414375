@import "../vars";
@import "../mixins";

.event-news {
  @include container($container-size);
  border-bottom: 1px solid $font-color;
  padding-bottom: $base-spacing*1.5;
  padding-top: $base-spacing*1.5;
  display: flex;

    &:first-of-type {
        padding-top: 0;
    }

  &:last-of-type {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  a {
    flex: 1;
  }

  @include styleBelow(635px) {
    flex-flow: column;
  }

  @include styleBelow($container-size) {
    padding: $base-spacing/2 0;
    margin-right: $base-spacing / 2;
    margin-left: $base-spacing / 2;
  }

  &__mobile-wrapper {
    display: flex;
    width: 100%;

    @include styleBelow(425px) {
      display: block;
    }
  }

    &__wrapper {
        margin-bottom: $base-spacing*1.5;
    }

  &__infos {
    display: flex;
    flex-flow: column;
    margin-left: $base-spacing*4;
    margin-top: $base-spacing/3;

    @include styleBelow(1000px) {
      margin-left: $base-spacing;
    }

    @include styleBelow(775px) {
      margin-top: 0;
    }

    @include styleBelow(650px) {
      margin-left: $base-spacing/2;
    }

    @include styleBelow(635px) {
      margin-left: 0;
    }
  }

  &--embedded {
    .event-news__infos {
      margin-left: 0;
    }
  }

  &__date {
    font-weight: lighter;
    color: $primary-color;
    margin-bottom: $base-spacing/3;
  }

  &__headline {
    text-transform: uppercase;
    font-size: 1.3em;
    margin-bottom: 5px;
    font-weight: normal;

    @include styleBelow(900px) {
      font-size: 1.1em;
    }


    @include styleBelow(500px) {
      font-size: 1em;
    }

    a {
      color: $font-color;
      transition: $hover-transition-time;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &__logo {
    display: block;

    @include styleBelow(635px) {
      margin-bottom: $base-spacing;
    }
  }

  &__subline {
    font-size: 1.1em;
    font-weight: lighter;
    letter-spacing: 0.05em;

    @include styleBelow(900px) {
      font-size: 1em;
    }

    @include styleBelow(500px) {
      font-size: 0.9em;
    }
  }

  &__more {
    text-transform: uppercase;
    font-size: 1.2em;
    margin-top: $base-spacing*2.5;
    font-weight: lighter;

    @include styleBelow(1550px) {
      margin-bottom: $base-spacing;
    }

    @include styleBelow(900px) {
      font-size: 1.1em;
    }

    @include styleBelow(775px) {
      margin-top: $base-spacing*2;
    }

    @include styleBelow(425px) {
      margin-top: $base-spacing/2;
      margin-bottom: $base-spacing/2;
      text-align: right;
    }

    a {
      color: $primary-color;
      transition: $hover-transition-time;

      @include styleBelow(775px) {
        white-space: nowrap;
      }

      &:hover {
        color: $font-color;
      }
    }
  }

  &__news-spacer {
    flex: 1;
  }

  &__spacer {
    flex: 1;

    @include styleBelow(425px) {
      display: none;
    }
  }

  &__further-news {

      a {
          text-transform: uppercase;
          font-size: 1.2em;
          font-weight: 300;
          transition: $hover-transition-time;
          color: $font-color;

          &:hover {
              color: $primary-color;
          }
      }

    @include styleBelow(900px) {
      font-size: 1.1em;
    }
  }
}
