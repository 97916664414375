@import "../vars";
@import "../mixins";

.image-text {
  @include container($container-size);
  min-height: 310px;
    margin-bottom: $base-spacing*1.5;

  @include styleBelow(1550px) {
    padding: 0 $base-spacing;
  }

  @include styleBelow(768px) {
    display: block;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  &__text {
    //flex: 2.5;
  }

    p {
        margin-top: 0;
        margin-bottom: 0;
    }

  img {
    float: left;
    padding-right: $base-spacing;
    padding-bottom: $base-spacing/2;
    display: block;
    //margin-right: $base-spacing*2;
    //max-width: 100%;
    //height: auto;

    @include styleBelow(768px) {
      width: 100%;
      padding: 0;
      margin-bottom: $base-spacing;
    }
  }
}