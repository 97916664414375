@import "../vars";
@import "../mixins";

.ticket-slider {
    //margin-bottom: $base-spacing*1.5;
    position: relative;

    &__banner-img {
        width: 100vw; //percent width is buggy but viewport width works fine
        height: auto;
        display: block;
    }

    &__event-logo {
        position: absolute;
        //right: $base-spacing*7; //wtf
        right: calc((100vw - #{$container-size}) / 2); //this should be way more reliable when scaling down
        top: 15%;
        @include styleBelow(1600px) {
            right: $base-spacing;
            top: $base-spacing;
        }
        @include styleBelow(968px) {
            display: none;
        }
    }

    &__infos-wrapper {
        background-color: $light-grey-background;
    }

    &__infos {
        display: flex;
        color: $font-color;
        font-size: 1.1em;
        padding: $base-spacing/2;
        @include container($container-size);
    }

    &__info-title {
        text-transform: uppercase;
        font-weight: normal;

        a {
            color: $font-color;

            &:hover {
                color: $primary-color;
            }
        }
    }

    &__info-details {
        font-weight: lighter;
        margin-right: 0;
    }

    &__spacer {
        flex: 1;
    }

    &__items-wrapper {
        background-color: $dark-grey-background;
        margin-bottom: $base-spacing*1.5;
        height: 30px;
    }

    &__items {
        display: flex;
        justify-content: center;
    }

    &__item {
        border-bottom: 2px solid $font-color;
        width: 80px;
        margin-top: $base-spacing/2;
        margin-right: $base-spacing;

        &:hover {
            border-bottom: 2px solid $primary-color;
        }

        &--active {
            border-bottom: 2px solid $primary-color;
        }
    }
}