@import "../mixins";

.longines {
  @include container();
  display: flex;
  justify-content: space-between;
    @include styleBelow(1550px){
        padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
        padding: 0 $base-spacing/2;
    }
  &__banner {
    flex: 1;
  }

  &__image {
    width: 100%;
    display: block;
    height: auto;
  }
}

.longines-v2 {
    @include container();
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
    @include styleBelow(1550px){
        padding: 0 $base-spacing;
    }
    @include styleBelow(1284px){
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @include styleBelow(425px) {
        padding: 0 $base-spacing/2;
    }
}

#loginesBox {
  background: url(/static/img/longines/bg.gif) no-repeat;
  width: 590px;
  height: 170px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  @include styleBelow(1500px) {
    display: none;
  }

}

#loginesBox .logo {
  background: url(/static/img/longines/logoS.png) no-repeat;
  width: 590px;
  height: 170px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

#loginesBox .time {
  background: url(/static/img/longines/timee.png) no-repeat;
  width: 400px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  left: 365px;
  z-index: 18;
  display: none;
}

#loginesBox .time div {
  float: left;
  display: block;
  margin-top: 20px;
}

#loginesBox .time div span {
  float: left;
  display: block;
  overflow: hidden;
  line-height: 31px;
  height: 31px;
  width: 15px;
}

#loginesBox .time .day span {
  float: left;
  line-height: 31px;
  height: 34px;
  overflow: hidden;
  width: 17px;
  display: block
}

#loginesBox .time .day {
  margin-left: 20px;
}

#loginesBox .time .hour {
  margin-left: 10px;
  margin-top: 24px
}

#loginesBox .time .minute {
  margin-left: 15px;
  margin-top: 24px
}

#loginesBox .time .second {
  margin-left: 20px;
  margin-top: 24px
}

#loginesBox .logoEnd {
  background: url(/static/img/longines/logo.png) no-repeat;
  width: 590px;
  height: 170px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

#loginesBox .horloge {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 25px;
  left: 240px;
  display: none;
}

#loginesBox .horlogeFIN {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 100px;
  left: 15px;
  display: none;
}
