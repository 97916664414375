@import "../vars";
@import "../mixins";

.event-boxen {
  @include container($container-size);
  @include grid();
  margin-bottom: $base-spacing/2;

  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  &__item {
    display: block;
    //background-color: $light-grey;
    @include gridItem(100%, 3, $base-spacing);
    margin-bottom: $base-spacing;

    @include styleBelow(845px) {
      @include gridItem(100%, 2, $base-spacing);

      &:nth-of-type(odd){
        margin-right: $base-spacing;
      }
    }


    @include styleBelow(570px) {
      @include gridItem(100%, 2, $base-spacing/2);

      &:nth-of-type(odd){
        margin-right: $base-spacing/2;
      }
    }

    @include styleBelow(500px) {
      @include gridItem(100%, 1, $base-spacing);
    }

    &:hover > .event-boxen__overlay .event-boxen__overlay-hover {
      transition: $transition ease;
      top: 0px;
    }
  }

  &__overlay {
    position: relative;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &-img {
      z-index: 1000;
    }

    &-hover {
      top: 100%;
      position: absolute;
      z-index: 2000;
      transition: $transition ease;
    }
  }

  &__info {
    font-weight: lighter;
    text-transform: uppercase;


    a {
      color: $font-color;
      transition: $hover-transition-time;

      @include styleBelow(555px) {
        font-size: 0.85em;
      }

      @include styleBelow(500px) {
        font-size: 1em;
      }


      @include styleBelow(375px) {
        font-size: 0.9em;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }

  h3 {
    margin-top: $base-spacing/3;
    margin-bottom: 0;
    font-size: 1em;
  }
}