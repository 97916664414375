@import "../vars";
@import "../mixins";

.search-result {
  @include container();

  @include styleBelow($container-size) {
    padding-left: $base-spacing / 2;
    padding-right: $base-spacing / 2;
  }

  &__list {
    list-style: none;
    padding-left: 0px;
  }

  .paginator {
    margin-bottom: $base-spacing / 2;

    a {
      font-size: 1.1rem;
    }

    a.active {
      color: $primary-color;
    }

  }

  .highlight {
    font-weight: bolder;
  }

  &__more {
    color: $primary-color;
    &:hover {
      color: $font-color;
    }
  }

}