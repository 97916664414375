@import "../vars";
@import "../mixins";

.archive-list {
    margin-bottom: $base-spacing*1.5;


    &__category {
        margin-bottom: $base-spacing;
        border-bottom: 1px solid $font-color;
        padding-bottom: $base-spacing;
    }

    &__entry {
        display: flex;
        margin-bottom: $base-spacing / 2;
        @include styleBelow(580px) {
            flex-flow: column;
        }
    }

    &__info {
        flex: 1;
    }

    a {
        color: $font-color;
        font-size: 1.2rem;
        &:hover {
            color: $primary-color;
        }
    }

    img {
        margin-right: $base-spacing / 2;
        display: block;
        @include styleBelow(580px) {
            margin-right: 0px;
            margin-bottom: $base-spacing / 2;
        }
    }
}