.mediabox {
    @include container($container-size);
    background-color: #00b2a9;
    display: flex;
    justify-content: center;
    align-items: center;
    .media-text {
        text-transform: uppercase;
        margin-right: 1rem;
    }
    .media-svg {
        height: 1.8rem;
        width: 1.8rem;
        color: white;
        margin-right: 1rem;
        svg {
            fill: currentColor;
            height: 100%;
            width: 100%;
        }
    }
}
.extra-padding {
    padding: 0 15px;
}

@media (min-width: 426px) {
    .extra-padding {
        padding: 0 30px;
    }
}