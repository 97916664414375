@import "../vars";
@import "../mixins";

.video-gallery {
  $transition-time: all 500ms;

  @include grid();
  margin-bottom: $base-spacing*1.5;

  &__picture {
    @include gridItem(100%, 4, 0px);
    margin-bottom: 0;
    position: relative;

    @include styleBelow(1300px) {
      @include gridItem(100%, 3, 0px);
    }

    @include styleBelow(768px) {
      @include gridItem(100%, 2, 0px);
    }

    @include styleBelow(425px) {
      @include gridItem(100%, 1, 0px);
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__video {
    position: absolute;
    top: 43%;
    right: 46%;

    transition: $transition-time;

    @include styleBelow(768px) {
      top: 41%;
    }

    @include styleBelow(550px) {
      top: 36%;
      right: 43%;
    }

    @include styleBelow(425px) {
      top: 42%;
      right: 45%;
    }

    @include styleBelow(320px) {
      top: 38%;
      right: 45%;
    }

    &:hover {
      transform: scale(1.2) rotate(-5deg);
    }
  }
}


.detail-content {
  $detail-container-size: 1000px;
  $transition-time: all 500ms;

  &__video-gallery {
    @include container($detail-container-size);
    @include grid();
    margin-bottom: $base-spacing*1.5;
  }

  &__video-picture {
    @include gridItem(100%, 3, 0px);
    margin-bottom: 0;
    position: relative;

    @include styleBelow(950px){
      @include gridItem(100%, 2, 0px);
    }

    @include styleBelow(425px){
      @include gridItem(100%, 1, 0px);
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__video {
    position: absolute;
    top: 28%;
    right: 44%;
    transition: $transition-time;

    @include styleBelow(1200px){
      top: 25%;
      right: 44%;
    }

    @include styleBelow(1200px){
      top: 20%;
      right: 44%;
      width: 45px;
    }

    @include styleBelow(970px){
       top: 18%;
       right: 44%;
       width: 40px;
     }

    @include styleBelow(950px){
      top: 25%;
      right: 44%;
      width: 45px;
    }

    @include styleBelow(850px){
      top: 23%;
      right: 44%;
      width: 40px;
    }

    @include styleBelow(780px){
      top: 20%;
      right: 44%;
    }

    @include styleBelow(768px){
      top: 30%;
      right: 45%;
      width: 45px;
    }

    @include styleBelow(650px){
      top: 28%;
      right: 44%;
      width: 40px;
    }

    @include styleBelow(550px){
      top: 24%;
      right: 44%;
      width: 38px;
    }

    @include styleBelow(425px){
      top: 32%;
      right: 44%;
      width: 50px;
    }

    @include styleBelow(360px){
      top: 29%;
      right: 44%;
      width: 45px;
    }


    @include styleBelow(320px){
      top: 27%;
      right: 44%;
      width: 45px;
    }



    &:hover {
      transform: scale(1.2) rotate(-5deg);
    }
  }
}