@import "../vars";
@import "../mixins";

.linked-images {
    @include container($container-size);
    @include grid();
    margin-bottom: $base-spacing*1.5;
    line-height: 150%;

    @include styleBelow(1550px) {
        padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
        padding: 0 $base-spacing/2;
    }

    &__item {
        @include styleAbove(1141px) {
            @include gridItem(100%, 6, 10px);
        }
        @include styleBetween(625px, 1140px) {
            @include gridItem(100%, 4, 10px);
        }
        @include styleBelow(624px) {
            @include gridItem(100%, 2, 10px);
        }
    }

    &__subline {
        font-weight: lighter;
        font-size: 0.9rem;
    }
}