@import "../vars";
@import "../mixins";


.sponsoring {
  @include container($container-size);
  margin-bottom: $base-spacing*1.5;


  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }


  &__text {
    margin-bottom: $base-spacing;
  }

  &__items {
    @include grid();
  }

  &__item {
    @include gridItem(100%, 4, $base-spacing);

    @include styleBelow(1250px){
      @include gridItem(100%, 3, $base-spacing);

      &:nth-of-type(4n){
        margin-right: $base-spacing;
      }
    }

    @include styleBelow(768px){
      @include gridItem(100%, 2, $base-spacing);

      &:nth-of-type(odd){
        margin-right: $base-spacing;
      }
    }

    @include styleBelow(460px) {
      @include gridItem(100%, 2, $base-spacing/2);

      &:nth-of-type(odd){
        margin-right: $base-spacing/2;
      }
    }

    @include styleBelow(445px) {
      @include gridItem(100%, 1, $base-spacing/2);
    }
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__headline {
    margin: 0;
    padding: 10px;
    background-color: $primary-color;
    transition: $hover-transition-time;

    &:hover {
      background-color: $font-color;
    }
  }

  h3 {
    color: $font-color;
    font-weight: lighter;
    text-transform: uppercase;


    @include styleBelow(1550px){
     font-size: 1.1em;
    }

    @include styleBelow(1250px){
      font-size: 1em;
    }

    &:hover {
      color: $primary-color;
    }
  }
}