@import "../vars";
@import "../mixins";


.copyright-bg {
  background-color: $default-background;
  height: 50px;

  @include styleBelow(375px){
    height: 70px;
  }
}

.copyright {
  @include container($container-size);
  padding: $base-spacing/2;
  text-align: center;

  &__text {
    font-weight: lighter;

    @include styleBelow(425px){
      font-size: 0.9em;
    }

    @include styleBelow(375px){
      font-size: 1em;
    }
  }

  &__link {
    font-weight: lighter;
  }

  a {
    color: $font-color;
    transition: $hover-transition-time;

    @include styleBelow(425px){
      font-size: 0.9em;
    }

    @include styleBelow(375px){
      font-size: 1em;
    }

    &:hover {
      color: $primary-color;
    }
  }

  span {
    margin-left: 5px;
  }
}