@import "../vars";
@import "../mixins";

.event-series-banner {
  @include container($container-size);
  margin-bottom: $base-spacing*1.5;
  display: flex;

  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(800px) {
    flex-flow: column;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  &__event-logos {
    //flex: 1;
    margin-right: $base-spacing;

    @include styleBelow(1024px) {
      margin-right: $base-spacing/2;
    }

    @include styleBelow(800px) {
      margin-bottom: $base-spacing/2;
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__longines-counter {
    //flex: 1;

    img {
      display: block;
      width: 100%;
    }
  }
}