@import "../vars";
@import "../mixins";


.navigation {
    height: $nav-height;
    font-family: "Aller", sans-serif;
    //background-color: $primary-color;
    &__content {
        @include container($container-size);
        display: flex;

        @include styleBelow(1550px) {
            padding: 0 $base-spacing;
        }

        @include styleBelow(425px) {
            padding: 0 $base-spacing/2;
        }
    }

    &__logo {
        padding-top: $base-spacing/2;

        img {
            display: block;
            width: 100%;
        }
    }

    &__spacer {
        flex: 1;
    }

    &__menu {
        font-size: 2rem;
        line-height: 2rem;
        text-transform: uppercase;
        font-weight: lighter;
        margin-right: $base-spacing;
        padding-top: $base-spacing;
        display: flex;
        transition: .5s;
        cursor: pointer;
        user-select: none;

        @include styleBelow(375px) {
            margin-right: $base-spacing/2;
        }

        @include styleBelow(500px) {
            margin-left: $base-spacing/2;
            margin-right: $base-spacing/2;
        }

        @include styleBelow(340px) {
            padding-top: 25px;
        }


        &:hover {
            color: $primary-color;

            .navigation__menu-button span {
                background: $primary-color;
            }
        }
    }

    &__menu-button {
        width: 25px;
        height: 2rem;
        margin: 5px 2px 0px 2px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $font-color;
            border-radius: 0.33em;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }

        span:nth-child(1) {
            top: 0;
        }

        span:nth-child(2), span:nth-child(3) {
            top: 0.5rem;
        }

        span:nth-child(4) {
            top: 1rem;
        }

        &.open span:nth-child(1) {
            top: 1rem;
            width: 0%;
            left: 50%;
        }

        &.open span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &.open span:nth-child(4) {
            top: 1rem;
            width: 0%;
            left: 50%;
        }

    }

    &__search {
        padding-top: $base-spacing;
        cursor: pointer;

        @include styleBelow(340px) {
            padding-top: 25px;
        }

        &--hidden {
            display: none;
        }


        i {
            font-size: 2rem;
            color: $font-color;

            &:hover {
                color: $primary-color;
            }
        }
    }

    $languageSwitcherBreakFixWidth: 450px;

    &__dropdown {
        padding-top: $base-spacing;

        // FIX: ENGARDE-97
        @include styleBelow($languageSwitcherBreakFixWidth) {;
            min-width: 50px;
            line-height: normal;
        }
    }

    &__dropdown-button {
        font-size: 2em;
        text-transform: uppercase;
        font-weight: lighter;
        text-align: center;

        @include styleBelow(500px) {
            margin-right: $base-spacing;
        }

        // FIX: ENGARDE-97
        @include styleBelow($languageSwitcherBreakFixWidth) {
            font-size: 1.3em;
            margin-right: 0;
        }

        &:hover {
            color: $primary-color;
        }
    }

    .dropdown-arrow {
        font-size: 1.5rem;

        // FIX: ENGARDE-97
        @include styleBelow($languageSwitcherBreakFixWidth) {
            font-size: 0.6em;
        }
    }


    .arrow-muster {
        text-transform: none;
    }

    &__dropdown {
        transition: color $transition;
        display: inline-block;
        align-items: center;
        justify-content: center;
        color: $font-color;
        position: relative;
        user-select: none;
        line-height: 2rem;

        &--hidden {
            display: none;
        }

        i {
            font-weight: lighter;
        }

        i:hover {
            color: $primary-color;
        }
    }

    &__dropdown:hover {
        color: $primary-color;

        i:hover {
            color: $primary-color;
        }
    }

    &__dropdown-content {
        display: none;
        position: absolute;
        background-color: $font-color;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        a {
            padding: $base-spacing /2 $base-spacing;
            text-decoration: none;
            display: block;
            color: $primary-color;
        }

        a:hover {
            transition: color $transition;
            transition: background-color $transition;
            background-color: $primary-color;
            color: $font-color;
        }

        &--active {
            display: block;
        }
    }

    &__lang {
        &-switch {
            display: flex;
            padding-top: $base-spacing;
            &--hidden {
                display: none;
            }
        }
        &-link {
            margin-left: $base-spacing / 3;
            &:first-of-type {
                margin-left: 0;
            }
        }
        &-img {
            display: block;
            height: 2rem;
            width: auto;
            border: 1px solid white;
        }
    }
}


