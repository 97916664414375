@import "../vars";
@import "../mixins";

.event-infos {
    @include container($container-size);
    text-transform: uppercase;
    margin-bottom: $base-spacing*1.5;

    @include styleBelow(1550px) {
        padding: 0 $base-spacing;
    }

    @include styleBelow(425px) {
        padding: 0 $base-spacing/2;
    }

    &__headline-wrapper {
        display: flex;
    }

    &__spacer {
        flex: 1;
    }

    h1 {
        color: $font-color;
        font-size: 2.0em;
        margin: $base-spacing/2 0;
        font-weight: normal;
        line-height: 120%;
        border-bottom: 2px solid $primary-color;
        padding-bottom: $base-spacing/6;

        @include styleBelow(1300px) {
            font-size: 1.7em;
        }

        @include styleBelow(650px) {
            font-size: 1.5em;
        }

        @include styleBelow(320px) {
            font-size: 1.4em;
        }
    }

    &__info {
        text-transform: none;
    }

    &__time {
        font-size: 1.1em;
        margin-bottom: $base-spacing/3;

        @include styleBelow(320px) {
            margin-bottom: 5px;
        }
    }

    &__place {
        font-size: 1.1em;
        color: $font-color;
        transition: $hover-transition-time;

        &:hover {
            color: $primary-color;
            transition: $hover-transition-time;
        }
    }

    &__content {
        display: flex;

        @include styleBelow(900px) {
            flex-flow: column;
        }
    }

    &__content-wrapper {
        display: block;
    }

    &__buttons {
        margin-top: $base-spacing;
        margin-bottom: $base-spacing*2;

        @include styleBelow(900px) {
            display: flex;
            margin-top: $base-spacing;
            margin-bottom: $base-spacing;
        }

        @include styleBelow(600px) {
            flex-flow: column;
            //max-width: 200px;
        }
    }

    &__button {
        background-color: $primary-color;
        border: 3px solid $primary-color;
        color: $font-color;
        padding: 6px 12px;
        margin-bottom: $base-spacing/2;
        text-align: center;
        transition: $hover-transition-time;
        white-space: nowrap;
        display: block;

        @include styleBelow(1300px) {
            font-size: 0.9em;
        }

        @include styleBelow(900px) {
            margin-bottom: 0;
            margin-right: $base-spacing/2;

            &:last-of-type {
                margin-right: 0;
            }
        }

        @include styleBelow(650px) {
            font-size: 0.85em;
            letter-spacing: 0.1em;
            margin-right: $base-spacing/3;
        }

        @include styleBelow(600px) {
            margin-bottom: $base-spacing/3;
            margin-right: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: $font-color;
        }
    }

    &__social-media {
        display: flex;
        justify-content: center;

        a {
            font-weight: 300;
            color: $font-color;
            font-style: normal;
            margin-right: $base-spacing / 3;

            &:last-of-type {
                margin-right: 0px;
            }

            &:hover {
                color: $primary-color;
                transition: $hover-transition-time;
            }
        }

        .svg-inline--fa {
            font-size: 2em;
        }

        @include styleBelow(900px) {
            margin-bottom: $base-spacing;
            justify-content: start;
        }
    }

    i {
        font-size: 1.8em;
        transition: $hover-transition-time;
        color: $font-color;
        margin-right: $base-spacing;

        &:hover {
            color: $primary-color;
        }
    }

    &__iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
        padding-top: 0px;
        height: 0;
        overflow: hidden;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__trailer {
        flex: 1;

        //@include styleBelow(1300px) {
        //    flex: none;
        //}
    }
}
