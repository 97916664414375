@import "../vars";
@import "../mixins";


.dividing-line {
  @include container($container-size);
  border-bottom: 1px solid $font-color;
  margin-bottom: $base-spacing*1.5;


  @include styleBelow(1550px){
    margin: 0 $base-spacing;
    margin-bottom: $base-spacing*1.5;
  }

  @include styleBelow(425px) {
    margin: 0 $base-spacing/2;
    margin-bottom: $base-spacing*1.5;
  }
}