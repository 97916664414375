@import "../vars";
@import "../mixins";

.two-columned-text {
  @include container($container-size);
  display: flex;
  line-height: 150%;
    margin-bottom: $base-spacing*1.5;

  @include styleBelow(550px) {
    display: block;
  }

  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

  &__text-item {
    //flex: 1;
    margin-right: $base-spacing*2;

    &:last-of-type {
      margin-right: 0;
    }

    @include styleBelow(768px) {
      margin-right: $base-spacing*1.5;
    }

    @include styleBelow(550px) {
      flex-direction: column;
      margin-bottom: $base-spacing;
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

    p {
        margin-top: 0;
        margin-bottom: 0;
    }
}