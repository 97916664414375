@import "../vars";
@import "../mixins";

.touren-banner{
  margin-bottom: $base-spacing*1.5;

  &__wrapper {
    position: relative;
  }

  &__banner-img {
    display: block;
    width: 100%;
  }

  &__event-logo {
    display: block;
    position: absolute;
    left: $base-spacing*7;
    top: $base-spacing*3;

    @include styleBelow(1550px){
      top: $base-spacing*2;
      left: $base-spacing*5;
    }

    @include styleBelow(1250px) {
      left: $base-spacing*4;
      width: 157px;
      height: 101px;
    }

    @include styleBelow(1000px) {
      left: $base-spacing*3;
      top: $base-spacing*1.5;
    }

    @include styleBelow(900px) {
      top: $base-spacing;
    }

    @include styleBelow(750px) {
      top: $base-spacing/2;
    }

    @include styleBelow(670px) {
      top: $base-spacing/3;
    }

    @include styleBelow(600px) {
      width: 105px;
      height: 67px;
      top: $base-spacing/2;
      left: $base-spacing;
    }

    @include styleBelow(450px) {
      top: $base-spacing/3;
    }

    @include styleBelow(425px) {
      display: none;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}