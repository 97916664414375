@import "../vars";
@import "../mixins";

.contact {
    @include container(800px);

    @include styleBelow(800px - ($base-spacing / 2)) {
        margin-left: $base-spacing / 2;
        margin-right: $base-spacing / 2;
    }

    &__item {
        margin-bottom: $base-spacing / 2;
        display: flex;

        @include styleBelow(500px) {
            display: block;
        }

        &--content-right {
            justify-content: flex-end;
        }
    }

    &__label {
        flex: 1;
        padding: $base-spacing / 6;

        @include styleBelow(500px) {
            display: block;
            width: 100%;
        }
    }

    &__input {
        flex: 2;
        padding: $base-spacing / 6;


        &--textarea {
            min-height: 150px;
            resize: vertical;
            font-family: 'Martel Sans', sans-serif;
        }

        @include styleBelow(500px) {
            width: 100%;

        }
    }

    &__send {
        color: $font-color;
        background-color: $primary-color;
        border: 3px solid $primary-color;
        padding: 7px 13px;
        text-transform: uppercase;
        font-weight: normal;
        letter-spacing: 1px;
        transition: $hover-transition-time;
        cursor: pointer;

        @include styleBelow(1250px) {
            padding: 3px 9px;
            font-size: 0.9em;
            text-align: center;
        }

        @include styleBelow(1170px) {
            margin-right: 0;
            margin-bottom: $base-spacing/3;
        }

        @include styleBelow(880px) {
            margin-right: $base-spacing/3;
        }

        @include styleBelow(475px) {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0);
        }
    }

    &__message {
        padding: $base-spacing / 6;
        margin-bottom: $base-spacing / 3;

        &--success {
            color: #28a745;
        }
        &--failure {
            color: #dc3545;
        }
    }
}
