@import "../vars";
@import "../mixins";

.text {
  @include container($container-size);
  margin-bottom: $base-spacing*1.5;
  line-height: 150%;

  @include styleBelow(1550px){
    padding: 0 $base-spacing;
  }

  @include styleBelow(425px) {
    padding: 0 $base-spacing/2;
  }

    p {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.detail-content {
  $detail-container-size: 1000px;


  &__text {
    @include container($detail-container-size);
    margin-bottom: $base-spacing*1.5;

      p {
          margin-bottom: 0;
          margin-top: 0;
      }
  }
}
