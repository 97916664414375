@import "../vars";
@import "../mixins";

.event-slider {
    $transition: all 800ms;

    @include container($container-size);

    //@include grid(); flex is blocking the slider
    margin-bottom: $base-spacing*1.5;

    @include styleBelow($container-size) {
        margin-left: $base-spacing;
        margin-right: $base-spacing;
    }

    &__picture {
        width: 100%;
    }

    &__item {
        display: block;
        // background-color: blue;

        $numElem: 4;
        @include styleBelow(1200px) {
            width: calc(#{100% / 3} - #{2 * $base-spacing / 3});
        }
        @include styleBelow(845px) {
            width: calc(#{100% / 2} - #{1 * $base-spacing / 2});
        }
        @include styleBelow(500px) {
            width: 100%;
        }

        width: calc(#{100% / $numElem} - #{($numElem - 1) * $base-spacing / $numElem});
        margin-right: $base-spacing / 2;
        margin-left: $base-spacing / 2;
        margin-bottom: $base-spacing;

        &:hover > .event-slider__overlay .event-slider__overlay-hover {
            transition: $transition ease;
            top: 0px;
        }
    }

    &__overlay {
        position: relative;
        overflow: hidden;

        &-img {
            width: 100%;
            height: auto;
            z-index: 1000;
        }

        &-hover {
            top: 100%;
            width: 100%;
            height: auto;
            position: absolute;
            z-index: 2000;
            transition: $transition ease;
        }
    }

    &__info {
        font-weight: lighter;
        text-transform: uppercase;

        a {
            color: $font-color;

            &:hover {
                color: $primary-color;
            }
        }
    }

    h3 {
        margin-top: $base-spacing/3;
        margin-bottom: 0;
        font-size: 1em;
    }
}